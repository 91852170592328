
import { Capacitor } from '@capacitor/core';

export default {
  name: 'TermsPage',
  layout: 'empty',
  computed: {
    isNative() {
      return true || Capacitor.isNativePlatform();
    },
  },
};
